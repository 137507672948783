import {useRef, useEffect} from 'react';

/**
 * @description A hook for returning the previous state of a value
 * @example
 * const Foo = ({bar}) => {
 *    const previousBar = usePrevious(bar);
 *
 *    return (
 *      <Fragment>
 *        it was {previousBar} and became {bar}
 *        <button onClick={changeBar}>Change bar</button>
 *      </Fragment>
 *    );
 * }
 */
const usePrevious = value => {
  const valueRef = useRef();

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
};

export default usePrevious;
