export const deepFreeze = <T>(freezeTarget: T): T => {
  if (freezeTarget !== null && typeof freezeTarget === 'object') {
    const items = Array.isArray(freezeTarget) ? freezeTarget : Object.values(freezeTarget);

    for (const item of items) {
      const isCircularReference = item === freezeTarget;

      if (!isCircularReference) {
        deepFreeze(item);
      }
    }

    return Object.freeze(freezeTarget);
  }

  if (typeof freezeTarget === 'function') {
    return Object.freeze(freezeTarget);
  }

  return freezeTarget;
};
